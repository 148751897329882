import './RemoveDetails.css';
import React, { useState, useEffect } from "react";
import { auth, db } from "../../configuration/firebaseConfig";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { ref, 
  orderByChild, 
  equalTo, 
  get, 
  query,
  // update, 
  remove } from "firebase/database";
  import logo from '../../images/logo.png';
import {
    NavLogo,
    NavbarContainer
} from '../../components/navbar/navbarElement';


const RemoveDetails = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userId, setUserId] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false); // New state for OTP verification
  // const [newName, setNewName] = useState(""); // State to store the new name for update

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      // Initialize reCAPTCHA verifier
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow OTP send
          console.log("recaptcha successfull",response);
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired');
        },
      });
    }
  }, []);

  // Function to validate and format the phone number
  const formatPhoneNumber = (number) => {
    const trimmedNumber = String(number.trim());

    // Validate that the phone number is 10 digits long and only contains numbers
    const isValidPhoneNumber = /^[6-9]\d{9}$/.test(trimmedNumber);
    if (!isValidPhoneNumber) {
      setMessage("Please enter a valid phone number.");
      return null;
    }

    // Add country code for India (+91)
    return `+91${trimmedNumber}`;
  };

  const fetchUserId = async (mobileNo) => {
    try {
       // Strip the +91 prefix from the phone number
       const mobileWithoutCountryCode = mobileNo.startsWith('+91') ? mobileNo.slice(3) : mobileNo;
       console.log("Querying for mobile number:", mobileWithoutCountryCode); // Debug the mobile number

      const userRef = ref(db, "India/User_Details");
      const quer = query(userRef, orderByChild("MobileNoOfUser"), equalTo((mobileWithoutCountryCode)));
      const snapshot = await get(quer);

     // console.log("Querying for mobile number:", mobileNo); // Debug the mobile number

      if (snapshot.exists()) {
        const userDetails = snapshot.val();
        console.log("User details from Firebase:", userDetails); // Log the fetched data
        const keys = Object.keys(userDetails);
        setUserId(keys[0]);
        console.log("fetched user id: ",keys)
        //setMessage(`User ID fetched: ${keys[0]}`);
        return true; // Return true if user is found
      } else {
        setMessage("No user found with this mobile number.");
        return false; // No user found
      }
      // return true;
    } catch (error) {
      console.error("Error fetching user ID:", error);
      //setMessage("Failed to fetch user ID. Please try again.");
      setMessage("Please try again.");
      return false;
    }
  };

  const sendOtp = async () => {
    setLoading(true);
    setMessage("");

    const formattedNumber = formatPhoneNumber(phoneNumber);  //`+91${phoneNumber.trim()}`;
    if (!formattedNumber) {
      setLoading(false);
      return; // Exit if phone number is invalid
    }
    // Fetch user ID based on the mobile number
    const userExists = await fetchUserId(phoneNumber);

    if (!userExists) {
      setLoading(false);
      return; // Exit if user does not exist
    }

    try {
      const appVerifier = window.recaptchaVerifier;
      const result = await signInWithPhoneNumber(auth, formattedNumber, appVerifier);
      setConfirmationResult(result);
      console.log("OTP is sent to: ",phoneNumber);
      setMessage(`OTP sent to ${phoneNumber}`);
    } catch (error) {
      console.error("Error sending OTP:", error);
      setMessage("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    setLoading(true);
    setMessage("");

    if (!confirmationResult) {
      setMessage("Please send OTP first.");
      setLoading(false);
      return;
    }

    try {
      await confirmationResult.confirm(otp);
      //setMessage("OTP verified successfully. You can now proceed with account deletion.");
      setMessage("OTP verification successful!");
      setIsOtpVerified(true); //set OTP verified status to true
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setMessage("Incorrect OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to delete user data (commented out for now)
  const deleteUserData = async () => {
    if (!userId) {
      console.log("No user ID found. Please ensure you have fetched the user.");
      setMessage("No user ID found.");
      return;
    }
    // Show confirmation dialog
     const confirmed = window.confirm("Are you sure you want to remove your account? This action cannot be undone.");

     if (!confirmed) {
      setMessage("Account removal canceled.");
      return;
    }  

    setIsDeleting(true);
    setMessage("");

    try {
      const userR= ref(db, `India/User_Details/${userId}`);
      await remove(userR); // Use dynamic userId
      setMessage("User data removed successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
      setMessage("Failed to remove your data. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };
  
// Function to update user data
// const updateUserData = async () => {
//   setMessage("");
//     if (!newName.trim()) {
//       setMessage("Please enter a valid name.");
//       return;
//     }
//     try {
//       await update(ref(db, `India/User_Details/${userId}`), { DistrictOfUserSearching: newName });
//       console.log("user id in update: ",userId);
//       setMessage("User name updated successfully.");
//     } catch (error) {
//       console.error("Error updating name:", error);
//       setMessage("Failed to update name. Please try again.");
//     }
//   };

  return (
    <div className="nav-background">
    <NavbarContainer>
      <NavLogo to='/'>
        <img src={logo} alt='logo' style={{width:'40px', height:'40px', marginRight:'10px'}} />
        The Health App
      </NavLogo>
    </NavbarContainer>

    <div className="container">
      <h2>Remove Details</h2>
      {/* <p>Please enter your Phone Number</p> */}

      <div>
        <input
          type="text"
          placeholder="Registered Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <button onClick={sendOtp} disabled={loading || isDeleting} className={loading ? "loading" : ""}>
          {loading ? "Sending OTP..." : "Send OTP"}
        </button>
      </div>

      <div>
        <input
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        <button onClick={verifyOtp} disabled={loading || isDeleting} className={loading ? "loading" : ""}>
          {loading ? "Verifying..." : "Verify OTP"}
        </button>
      </div>

    
      {/* Delete User Button, Show only if OTP is verified */}
      {isOtpVerified && (
      <div>
        <button onClick={deleteUserData} disabled={isDeleting || loading} className={isDeleting ? "loading" : ""}>
          {isDeleting ? "Deleting..." : "Delete Account"}
        </button>
      </div>
    )}

      {/* Update User Data Button */}
      {/* {confirmationResult && (
        <div>
          <input
            type="text"
            placeholder="Enter new name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <button onClick={updateUserData} disabled={loading || isDeleting}>
            {loading ? "Updating..." : "Update Name"}
          </button>
        </div>
      )} */}

      {message && <p>{message}</p>}
      <div id="recaptcha-container"></div>
    </div>
    </div>
  );
};

export default RemoveDetails;
